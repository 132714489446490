import React, { MouseEvent, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { IoMdLogIn } from 'react-icons/io';
import { MdHome } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../auth';
import ProfileDropdown from '../../profile-dropdown';
import ProfilePicture from '../../profile-picture';
import Tooltip from '../../utils/tooltip';
import DisclaimerModal from '../../video-chat/disclaimer-modal';
import './header.scss';
import Logo from './logo';

export default function Header() {
  const { isAuthenticated, loginWithRedirect } = useAuth();
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  function handleLogin() {
    loginWithRedirect();
  }

  function handleCancelDisclaimer() {
    setShowDisclaimer(false);
  }

  function handleConfirmDisclaimer() {
    setShowDisclaimer(false);
    handleLogin();
  }

  function handleLoginButtonClick(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    setShowDisclaimer(true);
  }

  return (
    <header className="app-header">
      <Navbar
        className="navbar-dark header-container justify-content-between"
        expand="lg">
        <Tooltip id="home" title="Home" placement="bottom">
          <NavLink className="text-white nav-link" to="/">
            <Logo className="header-home-icon" />
          </NavLink>
        </Tooltip>
        {!isAuthenticated && (
          <Tooltip id="login" title="Login" placement="bottom">
            <a
              className="text-white nav-link"
              href=""
              onClick={handleLoginButtonClick}>
              <IoMdLogIn size="2em" />
            </a>
          </Tooltip>
        )}
        {isAuthenticated && (
          <div className="text-white nav-link">
            <ProfileDropdown>
              <ProfilePicture className="header-profile-picture" />
            </ProfileDropdown>
          </div>
        )}
        <DisclaimerModal
          show={showDisclaimer}
          onCancel={handleCancelDisclaimer}
          onConfirm={handleConfirmDisclaimer}
        />
      </Navbar>
    </header>
  );
}
