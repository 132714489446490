import { getApiUrl } from '../../utils';
import { GetVideoChatTokenDTO, VideoChatTokenDTO } from './types';

export async function getVideoChatToken(
  data: GetVideoChatTokenDTO,
  getAuthToken: () => Promise<string> | undefined,
): Promise<VideoChatTokenDTO> {
  if (!data) {
    throw new Error(
      'tried to request a video chat token without supplying any data',
    );
  }

  const { appointment_code } = data;
  if (typeof appointment_code !== 'string' || appointment_code.length < 1) {
    throw new Error(
      'tried to request a video chat token without supplying an appointment code',
    );
  }

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  try {
    const authToken = await getAuthToken();
    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }
  } catch {}

  try {
    const response = await fetch(`${getApiUrl()}/video/token`, {
      method: 'POST',
      mode: 'cors',
      headers,
      body: JSON.stringify({
        appointment_code,
      }),
    });

    const token = await response.json();
    return token;
  } catch (error) {
    console.error(error);
    return {
      token: null,
      error: error.message,
    };
  }
}
