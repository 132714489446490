import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './disclaimer-modal.scss';

export type DisclaimerModalProps = {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export default function DisclaimerModal({
  show,
  onCancel,
  onConfirm,
}: DisclaimerModalProps) {
  return (
    <Modal show={show} onHide={onCancel} className="disclaimer-modal">
      <Modal.Header closeButton>
        <Modal.Title>Telehealth Disclaimer/Acknowledgement</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">
        <ol>
          <li>
            <p>
              I understand that this appointment will not be the same as a
              direct patient/health care provider visit due to the fact that I
              will not be in the same room as my health care provider.
            </p>
          </li>
          <li>
            <p>
              I understand there are potential risks to this technology,
              including interruptions, unauthorized access and technical
              difficulties. I understand that my health care provider or I can
              discontinue the telehealth appointment if it is felt that the
              videoconferencing connections are not adequate for the situation.
              I understand that I can discontinue the telehealth appointment at
              any time.
            </p>
          </li>
          <li>
            <p>
              I understand that my healthcare information may be shared with
              other individuals for scheduling and billing purposes. Others may
              also be present during the appointment other than my healthcare
              provider and specialty health care provider in order to operate
              the equipment. The above mentioned people will all maintain
              confidentiality of the information obtained. I further understand
              that I will be informed of their presence during the consultation
              and thus will have the right to request the following: (1) omit
              specific details of my medical history/physical examination that
              are personally sensitive to me; (2) ask non-medical personnel to
              leave the telehealth examination room; and/or (3) terminate the
              telehealth appointment at any time.
            </p>
          </li>
          <li>
            <p>
              In an emergency situation, I understand that the responsibility of
              the telehealth specialist or provider may be to direct me to
              emergency medical services, such as emergency room. Or the
              telehealth provider may discuss with and advise my local provider.
              The telehealth specialist’s or provider’s responsibility will end
              upon the termination of the telehealth connection.
            </p>
          </li>
          <li>
            <p>
              I have read this document carefully, and understand the risks and
              benefits of the telehealth appointment and have had my questions
              regarding the procedure explained and I hereby consent to
              participate in a telehealth appointment visit under the terms
              described herein.
            </p>
          </li>
        </ol>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
