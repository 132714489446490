import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useAuth } from '../../auth';
import { debugLog } from '../../utils';
import RippleLoader from '../utils/ripple-loader';
import Room from './room';
import './video-chat-room.scss';
import { getVideoChatToken } from './video-service';
import WaitingScreen from './waiting-screen';

export type VideoChatRoomProps = {
  appointmentCode: string;
};

export default function VideoChatRoom({ appointmentCode }: VideoChatRoomProps) {
  const { getTokenSilently } = useAuth();
  const { addToast } = useToasts();
  const [token, setToken] = useState<string | null>(null);
  const [goback, setGoback] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);

  function showErrorNotification(message: string) {
    addToast(message, {
      appearance: 'error',
      autoDismiss: false,
    });
    setGoback(true);
  }

  async function joinCall() {
    debugLog('attempting to join video chat');
    appointmentCode = appointmentCode || '';

    const result = await getVideoChatToken(
      {
        appointment_code: appointmentCode.trim(),
      },
      getTokenSilently,
    );

    if (!result) {
      debugLog(
        'failed to join video chat: no response was received from the server',
      );
      showErrorNotification(
        'Encountered an unexpected error while trying to join the appointment. Please try again.',
      );
    } else if (result.error) {
      debugLog(`failed to join video chat: ${result.error}`);

      const errorMessage = result.error.toLowerCase().trim();
      if (
        errorMessage ===
          'video does not exist, and user is not authenticated' ||
        errorMessage === 'can not join a call without participants'
      ) {
        setWaiting(true);
      } else {
        showErrorNotification(
          'Encountered an unexpected error while trying to join the appointment. Please try again.',
        );
      }
    } else if (result.token) {
      setWaiting(false);
      setToken(result.token);
      setTimeout(() => setReady(true), 500);
    }

    return result;
  }

  useEffect(() => {
    if (waiting) {
      const checkForPractitioner = setInterval(joinCall, 5000);
      return () => clearInterval(checkForPractitioner);
    }
  }, [waiting]);

  useEffect(() => {
    if (!token) {
      joinCall();
    }
  }, [token]);

  const handleLogout = useCallback(() => {
    setGoback(true);
    setToken(null);
    setReady(false);
  }, []);

  if (goback) {
    return <Redirect to="/"></Redirect>;
  }

  if (waiting) {
    return (
      <WaitingScreen
        appointmentCode={appointmentCode}
        message="Waiting for Other Party to Join with This Appointment ID:"
      />
    );
  }

  if (token && ready) {
    return (
      <Room
        appointmentCode={appointmentCode || ''}
        token={token}
        handleLogout={handleLogout}
      />
    );
  }

  return (
    <div className="chat-loading-container">
      <RippleLoader />
    </div>
  );
}
