import React, { RefObject, useEffect, useRef, useState } from 'react';
import {
  FaVideo,
  FaVideoSlash,
  FaVolumeMute,
  FaVolumeUp,
} from 'react-icons/fa';
import { IoIosReverseCamera } from 'react-icons/io';
import {
  LocalAudioTrack,
  LocalParticipant as LocalParticipantType,
  LocalTrackPublication,
} from 'twilio-video';
import { debugLog } from '../../../utils/debug';
import './local-participant.scss';
import { AudioTrackType, TrackType, VideoTrackType } from './types';
import {
  getMediaDevices,
  getParticipantAudioTracks,
  getParticipantVideoTracks,
  getTrack,
  isAudioTrack,
  isVideoTrack,
  toggleVideoDevice,
} from './utils';
import debounce from 'lodash.debounce';
import Toolbar from '../toolbar/toolbar';

export type LocalParticipantProps = {
  participant: LocalParticipantType;
  onAudioAttached: () => void;
  onVideoAttached: () => void;
};

export default function LocalParticipant({
  participant,
  onAudioAttached,
  onVideoAttached,
}: LocalParticipantProps) {
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [videoTracks, setVideoTracks] = useState<VideoTrackType[]>([]);
  const [audioTracks, setAudioTracks] = useState<AudioTrackType[]>([]);
  const [audioDeviceCount, setAudioDeviceCount] = useState(0);
  const [videoDeviceCount, setVideoDeviceCount] = useState(0);

  const videoRef = useRef() as RefObject<HTMLVideoElement>;
  const audioRef = useRef() as RefObject<HTMLAudioElement>;

  useEffect(() => {
    /* Track Subscriptions */

    /* local user started a new stream */

    function trackPublished(trackPublication: LocalTrackPublication) {
      const track = getTrack(trackPublication);
      if (track) {
        if (isVideoTrack(track)) {
          debugLog('local video track published');
          setVideoTracks(videoTracks => [track, ...videoTracks]);
        } else if (isAudioTrack(track)) {
          debugLog('local audio track published');
          setAudioTracks(audioTracks => [track, ...audioTracks]);
        }
      }
    }

    function trackSubscribed(track: TrackType) {
      if (isVideoTrack(track)) {
        debugLog('local video track subscribed');
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else if (isAudioTrack(track)) {
        debugLog('local audio track subscribed');
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    }

    /* local user finished an active stream */
    function trackUnsubscribed(track: TrackType) {
      if (isVideoTrack(track)) {
        debugLog('local video track unsubscribed');
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else if (isAudioTrack(track)) {
        debugLog('local audio track unsubscribed');
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    }

    /* Track Starting and Stopping */

    function trackStarted(track: TrackType) {
      if (isVideoTrack(track)) {
        debugLog('local video track started');
      } else if (isAudioTrack(track)) {
        debugLog('local audio track started');
      }
    }

    /* local user "muted" data in a stream */
    function trackStopped(track: TrackType) {
      if (isVideoTrack(track)) {
        debugLog('local video track stopped');
      } else if (isAudioTrack(track)) {
        debugLog('local audio track stopped');
      }
    }

    /* Track Enabling and Disabling */

    /* local user "unmuted" data in a stream */
    function trackEnabled(track: TrackType) {
      if (isVideoTrack(track)) {
        debugLog('local video track enabled');
      } else if (isAudioTrack(track)) {
        debugLog('local audio track enabled');
      }
    }

    /* local user "muted" data in a stream */
    function trackDisabled(track: TrackType) {
      if (isVideoTrack(track)) {
        debugLog('local video track disabled');
      } else if (isAudioTrack(track)) {
        debugLog('local audio track disabled');
      }
    }

    setAudioTracks(getParticipantAudioTracks(participant));
    setVideoTracks(getParticipantVideoTracks(participant));

    participant.on('trackPublished', trackPublished);
    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.on('trackStarted', trackStarted);
    participant.on('trackStopped', trackStopped);
    participant.on('trackEnabled', trackEnabled);
    participant.on('trackDisabled', trackDisabled);
  }, [participant]);

  useEffect(() => {
    const audioTrack = audioTracks[0] as LocalAudioTrack;
    if (audioTrack && audioRef.current) {
      audioTrack.attach(audioRef.current);
      onAudioAttached();
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack && videoRef.current) {
      videoTrack.attach(videoRef.current);
      onVideoAttached();
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  const updateMediaDevices = debounce(async function updateMediaDevices() {
    const { audio, video } = await getMediaDevices();
    if (audio.length !== audioDeviceCount) {
      setAudioDeviceCount(audio.length);
    }

    if (video.length !== videoDeviceCount) {
      setVideoDeviceCount(video.length);
    }
  }, 1000);

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', updateMediaDevices);
    updateMediaDevices();
    return () =>
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        updateMediaDevices,
      );
  }, []);

  async function nextVideoDevice() {
    try {
      await toggleVideoDevice(participant);
    } catch (err) {
      debugLog('failed to toggle the active video device');
      console.error(err);
    }
  }

  function toggleAudio() {
    const enabled = !audioEnabled;
    setAudioEnabled(enabled);

    for (const track of getParticipantAudioTracks(participant)) {
      if (track) {
        if (enabled) {
          track.enable();
        } else {
          track.disable();
        }
      }
    }
  }

  function toggleVideo() {
    const enabled = !videoEnabled;
    setVideoEnabled(enabled);

    for (const track of getParticipantVideoTracks(participant)) {
      if (track) {
        if (enabled) {
          track.enable();
        } else {
          track.disable();
        }
      }
    }
  }

  const AudioIcon = audioEnabled ? FaVolumeUp : FaVolumeMute;
  const VideoIcon = videoEnabled ? FaVideo : FaVideoSlash;

  return (
    <>
      <div className="participant">
        <video ref={videoRef} autoPlay={true} />
        <audio ref={audioRef} autoPlay={true} muted={true} />
      </div>
      <Toolbar>
        {audioDeviceCount > 0 && (
          <div className="audio-control" onClick={toggleAudio}>
            <AudioIcon />
          </div>
        )}
        {videoDeviceCount > 0 && (
          <div className="video-control" onClick={toggleVideo}>
            <VideoIcon />
          </div>
        )}
        {videoDeviceCount > 1 && (
          <div className="video-device-control" onClick={nextVideoDevice}>
            <IoIosReverseCamera />
          </div>
        )}
      </Toolbar>
    </>
  );
}
