import React, { useEffect } from 'react';
import { useAuth } from '../../auth';
import { Redirect } from 'react-router-dom';

export default function Login() {
  const { isAuthenticated, loginWithRedirect } = useAuth();
  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, []);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <></>;
}
