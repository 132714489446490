import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Home from '../../../routes/home';
import Login from '../../../routes/login';
import Room from '../../../routes/room';
import { debugLog } from '../../../utils';

export default function Main() {
  const location = useLocation();
  const [pendingReload, setPendingReload] = useState(false);

  useEffect(() => {
    const serviceWorkerEnabled = 'serviceWorker' in navigator;
    if (serviceWorkerEnabled) {
      debugLog('binding service worker event listeners');
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (navigator.serviceWorker.controller) {
          debugLog('new service worker detected');
          setPendingReload(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    debugLog('navigated to', location.pathname);
    if (pendingReload) {
      debugLog('reloading page because a new service worker is ready');
      window.location.reload();
    }
  }, [location]);

  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/room/:appointmentCode">
        <Room />
      </Route>
    </Switch>
  );
}
