import React from 'react';

export type LogoProps = {
  className?: string;
};

export default function Logo({ className }: LogoProps) {
  return (
    <svg
      className={className}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 200.000000 200.000000"
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path
          d="M1725 1238 c-143 -103 -234 -201 -298 -318 -30 -56 -67 -155 -67
-182 0 -4 31 -8 70 -8 64 0 70 2 74 23 33 172 57 240 119 335 24 37 65 91 91
120 26 28 46 52 44 52 -1 0 -16 -10 -33 -22z"
        />
        <path
          d="M302 1243 c2 -5 10 -20 17 -35 13 -25 9 -47 -42 -241 -32 -117 -57
-219 -57 -225 0 -9 50 -12 207 -10 l207 3 13 50 c7 28 13 53 13 57 0 4 -42 9
-94 10 l-93 3 48 180 c27 99 49 188 49 198 0 15 -13 17 -136 17 -75 0 -134 -3
-132 -7z"
        />
        <path
          d="M780 1244 c0 -3 7 -18 16 -34 16 -26 15 -35 -43 -251 l-60 -224 110
-3 c60 -1 112 -1 114 2 2 2 16 48 31 102 l28 99 57 3 c31 2 57 -1 57 -5 0 -5
-11 -49 -25 -98 -14 -50 -25 -93 -25 -97 0 -5 51 -8 113 -8 l114 0 66 248 c37
137 67 254 67 260 0 9 -38 12 -135 12 -108 0 -135 -3 -135 -14 0 -7 7 -19 16
-27 16 -13 15 -21 -12 -116 l-15 -53 -54 0 c-37 0 -55 4 -55 13 0 6 11 51 24
99 13 47 22 89 18 92 -7 8 -272 8 -272 0z"
        />
        <path
          d="M1442 1243 c2 -5 10 -20 17 -35 12 -24 11 -39 -13 -129 -15 -55 -26
-103 -23 -105 2 -2 20 20 41 50 36 53 129 147 196 199 l35 27 -128 0 c-71 0
-127 -3 -125 -7z"
        />
        <path
          d="M1646 1088 c-54 -93 -83 -182 -101 -310 l-6 -48 66 0 c60 0 66 2 60
18 -16 37 -35 129 -34 167 0 22 10 79 23 128 12 48 21 87 19 87 -1 0 -13 -19
-27 -42z"
        />
      </g>
    </svg>
  );
}
